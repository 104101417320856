import React from 'react';

import Cms from '@components/cms/Cms.component';
import {getCmsPaths, getCmsPageProps} from '@utils/cms';
import {replaceBanner} from '@components/home/heroBanner'; // Adjust the import path as needed

const Page = props => {
	const {cmsdata} = props;
	const modifiedCmsData = cmsdata ? replaceBanner(cmsdata) : null;

	return (
		<>
			<Cms cmsdata={modifiedCmsData}></Cms>
			<div id='dynamicstyle'></div>
		</>
	);
};

export async function getStaticProps({params}) {
	const slug = params && params.slug ? params.slug.join('/') : '/';

	const props = await getCmsPageProps(slug);

	let meta = {};
	if (props && props.result && props.result.cms) {
		meta = {
			title: props.result.cms.title,
			description: props.result.cms.meta_desc,
			keywords: props.result.cms.meta_keyword,
		};
	}
	return {
		props: {
			cmsdata: props?.result?.cms || null,
			meta,
		},
		revalidate: 60 * 15,
	};
}

export async function getStaticPaths() {
	const paths = await getCmsPaths();

	delete paths[0];
	return {
		paths:
			paths.map(node =>
				node.slug === '/' ? `${node.slug}` : `/${node.slug}`,
			) || [],
		fallback: true,
	};
}

Page.Layout = true;
export default Page;
