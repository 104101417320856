import styles from '@styles/home/heroBanner.module.scss';

const generateNewBannerHtml = () => `
  <section class="${styles.betterthatBanner}">
    <div class="${styles.backgroundOverlay}"></div>
    <div class="${styles.bannerContent}">
      <div class="${styles.contentWrapper}">
        <div class="${styles.textContent}">
          <h1>Welcome to Betterthat</h1>
          <p><strong>Better That</strong> is a Software as a Service (SaaS) developer within the Retail technology environment.</p>
          <p>It has developed / is developing new e-commerce platform accessing Millions of Products sourced from hundreds of retailers providing new ways of engagement between Retailers, Shoppers, and their respective Communities.<br>Their developments include:</p>
          
          <p><strong>Marketplace</strong> is a ready to trade, plug-and-play shopping platform pre-populated with millions of products from hundreds of retailers.</p>
          <p><strong>Drop Auctions</strong> is a unique, highly engaging and gamified shopping experience delivered via a reverse auction algorithm.</p>
          <p><strong>Multi-Vendor Retail products</strong> - an online retail marketplace to enable shoppers to identify the best price for an item through a reverse-auction application.</p>
        </div>
        <div class="${styles.imageContent}">
          <img src="/assets/home/group-of-people.png" alt="Betterthat illustration" />
        </div>
      </div>
    </div>
    <div class="${styles.licenseInfo}">
      <p  class="${styles.licenSes}">As of July'24, licenses to manage, further develop, and market within Australia, New Zealand and United Kingdom have been granted to:</p>
      <div class="${styles.licenseHolders}">
        <div class="${styles.licenseHolder}">
          <p>Promontory Point Pty Ltd t/a <strong>Better That Connect</strong></p>
          <a href="https://www.betterthatconnect.com/">
          <img src="/assets/home/better-that-connect-logo.png" alt="Better That Connect logo" /></a>
          <p>for Marketplace product</p>
        </div>
        <div class="${styles.licenseHolder}">
        
          <p><strong>DITYBIT</strong> PTY Ltd</p>
          <a href="https://ditybit.com/">
          <img src="/assets/home/ditybit-logo.png" alt="DITYBIT logo" class="${styles.smallImg}" /></a>
          <p>for the Drop Auction product</p>
        </div>
        <div class="${styles.licenseHolder}">
        <a href="https://scottpoint.com.au/">
          <p><strong>Scott Point</strong> Pty Ltd</p>
          <img src="/assets/home/sp-shop-logo.png" alt="SP SHOP logo" /></a>
          <p>for the Multi-Vendor Retail products.</p>
        </div>
      </div>
      <p class="${styles.licensesEnd}">Better That is about to commence development for its fourth product 'Data Analytics' that will provide valuable transactional data insights sourced from the licensed entities' trading data and from external retailers contributing to the above products. This product will also be licensed to a new entity.</p>
    </div>
  </section>
`;
export const replaceBanner = data => {
	if (!data || !data.raw_data) {
		return data;
	}

	try {
		const rawData = JSON.parse(data.raw_data);
		if (Array.isArray(rawData.blocks)) {
			const newBannerHtml = generateNewBannerHtml();

			rawData.blocks = rawData.blocks.map(block => {
				if (
					block.includes('class="shop-now-block"') &&
					block.includes('data-block-type="home_new"') &&
					block.includes('data-id="1"')
				) {
					return newBannerHtml;
				}
				return block;
			});

			return {
				...data,
				raw_data: JSON.stringify(rawData),
			};
		}
	} catch (error) {
		console.error('Error parsing or modifying raw_data:', error);
	}

	return data;
};
